.my-account-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(to right, blue, purple);
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.credits-info {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
}

.credits-info p {
  font-size: 18px;
  margin: 10px 0;
}

.account-actions {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
}

.tier-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.tier-button {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 20px;
  width: 250px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: white;
  text-align: center;
}

.tier-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.tier-button h3 {
  font-size: 24px;
  margin: 0;
}

.error {
  color: red;
}

@media (max-width: 768px) {
  .credits-info,
  .account-actions {
    width: 90%;
  }

  .tier-button {
    width: 100%;
    max-width: 300px;
  }
}

/* Dropdown Menu */
.dropdown {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dropdown-toggle {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: #4caf50;
  border-bottom: 1px solid white;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #45a049;
}

.auth-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.login-button,
.register-button {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.google-signin-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#google-signin-button {
  width: 250px;
}
