/* Instruction Popup Overlay */
.instruction-popup,
.invalid-fen-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Add this line to make the popup scrollable */
}

/* Instruction Content Box */
.instruction-content,
.invalid-fen-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  cursor: auto;
  text-align: left;
  color: black;
  max-height: 80%; /* Add this line to limit the height and make it scrollable */
  overflow-y: auto; /* Add this line to enable scrolling inside the content box */
}

.instruction-content h2,
.invalid-fen-content h2 {
  text-align: left;
  margin-top: 0;
}

.instruction-content p,
.invalid-fen-content p {
  text-align: left;
}

.instruction-content ol {
  text-align: left;
  padding-left: 20px;
}

.instruction-content button,
.invalid-fen-content button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.instruction-content button:hover,
.invalid-fen-content button:hover {
  background-color: #0056b3;
}

.invalid-fen-images img {
  max-width: 48%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upload-container {
  min-height: 100vh;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(to right, blue, purple);
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.toggle-button,
.analysis-button,
.upload-button,
.logout-button,
.finish-button {
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button:hover,
.analysis-button:hover,
.upload-button:hover,
.logout-button:hover,
.finish-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-button:active,
.analysis-button:active,
.upload-button:active,
.logout-button:active,
.finish-button:active {
  background-color: #3e8e41;
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.toggle-button {
  background-color: #6c757d;
}

.toggle-button:hover {
  background-color: #5a6268;
}

.drag-drop-area {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ddd;
}

.drag-drop-area.dragging {
  border-color: #4caf50;
  background-color: rgba(76, 175, 80, 0.2);
}

.file-input-label {
  display: block;
  cursor: pointer;
}

input[type='file'] {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.file-preview {
  max-width: 100px;
  height: auto;
  margin-top: 10px;
  border-radius: 5px;
}

.file-name {
  margin-top: 10px;
  color: #eee;
}

.upload-response img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .upload-form {
    width: 90%;
  }

  .upload-response,
  .analysis-response,
  .hint-response {
    margin-top: 20px;
  }

  .upload-response img {
    max-width: 100%;
    height: auto;
  }

  .toggle-button,
  .analysis-button,
  .upload-button,
  .logout-button,
  .finish-button {
    padding: 15px 30px;
    font-size: 18px;
  }
}

.move-turn-segmented-control {
  display: flex;
  margin-top: 20px;
}

.segmented-control-button {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: none;
  background-color: #e0e0e0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.segmented-control-button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.segmented-control-button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.segmented-control-button.active {
  background-color: #4caf50;
  color: white;
}

.logout-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Dropdown Menu */
.dropdown {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dropdown-toggle {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: #4caf50;
  border-bottom: 1px solid white;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #45a049;
}

.auth-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.register-button,
.login-button {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.google-signin-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#google-signin-button {
  width: 250px;
}

.instruction-content .turn-images {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.instruction-content .turn-images img {
  max-width: 48%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.example-analysis-image {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Add some space above the image */
}

.example-analysis-image img {
  max-width: 58%; /* Adjust to match the size of the turn-images */
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.instruction-content .disclaimer {
  color: rgba(0, 0, 0, 0.6); /* Fainter color */
  font-size: 14px; /* Slightly smaller font size */
  margin-top: 20px;
}
