.create-contract-container {
  min-height: 100vh;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(to right, blue, purple);
  color: white;
  text-align: center;
  padding: 20px;
}

.create-contract-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.create-contract-form label {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}

.create-contract-form input,
.create-contract-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.create-contract-form input::placeholder,
.create-contract-form select {
  color: #ddd;
}

.create-contract-form button {
  width: 200px;
  height: 50px;
  font-size: 16px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
}

.create-contract-form button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.create-contract-form button:active {
  background-color: #3e8e41;
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.create-contract-message {
  margin-top: 20px;
  color: white;
}

@media (max-width: 768px) {
  .create-contract-form {
    width: 90%;
  }
}

/* Instruction Popup Overlay */
.instruction-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Instruction Content Box */
.instruction-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  cursor: auto;
  text-align: left;
  color: black;
}

.instruction-content h2 {
  text-align: left;
  margin-top: 0;
}

.instruction-content p {
  text-align: left;
}

.instruction-content ol {
  text-align: left;
  padding-left: 20px;
}

.instruction-content button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
}

.instruction-content button:hover {
  background-color: #0056b3;
}
