/* Instruction Popup Overlay */
.instruction-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Add this line to make the popup scrollable */
}

/* Instruction Content Box */
.instruction-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  cursor: auto;
  text-align: left;
  color: black;
  max-height: 80%; /* Add this line to limit the height and make it scrollable */
  overflow-y: auto; /* Add this line to enable scrolling inside the content box */
}

.instruction-content h2 {
  text-align: left;
  margin-top: 0;
}

.instruction-content p {
  text-align: left;
}

.instruction-content ol {
  text-align: left;
  padding-left: 20px;
}

.instruction-content button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.instruction-content button:hover {
  background-color: #0056b3;
}

.example-analysis-image {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Add some space above the image */
}

.example-analysis-image img {
  max-width: 58%; /* Adjust to match the size of the turn-images */
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upload-container {
  min-height: 100vh;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(to right, blue, purple);
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.toggle-button,
.analysis-button,
.upload-button,
.logout-button {
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button:hover,
.analysis-button:hover,
.upload-button:hover,
.logout-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-button:active,
.analysis-button:active,
.upload-button:active,
.logout-button:active {
  background-color: #3e8e41;
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.toggle-button {
  background-color: #6c757d;
}

.toggle-button:hover {
  background-color: #5a6268;
}

.drag-drop-area {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ddd;
}

.drag-drop-area.dragging {
  border-color: #4caf50;
  background-color: rgba(76, 175, 80, 0.2);
}

.file-input-label {
  display: block;
  cursor: pointer;
}

input[type='file'] {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.file-preview {
  max-width: 100px;
  height: auto;
  margin-top: 10px;
  border-radius: 5px;
}

.file-name {
  margin-top: 10px;
  color: #eee;
}

.upload-response img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .upload-form {
    width: 90%;
  }

  .upload-response,
  .analysis-response,
  .hint-response {
    margin-top: 20px;
  }

  .upload-response img {
    max-width: 100%;
    height: auto;
  }

  .toggle-button,
  .analysis-button,
  .upload-button,
  .logout-button {
    padding: 15px 30px;
    font-size: 18px;
  }
}

.move-turn-segmented-control {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hint-mode-segmented-control {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.segmented-control-button {
  background-color: #f1f1f1;
  border: none;
  color: black;
  padding: 10px 20px; /* Adjust padding for better spacing */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  border-radius: 0;
  flex: 1;
  box-sizing: border-box;
  min-width: 150px; /* Ensure the button has a minimum width */
}

.segmented-control-button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.segmented-control-button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.segmented-control-button.active {
  background-color: #4caf50;
  color: white;
}

.segmented-control-button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.segmented-control-button.active {
  background-color: #4caf50;
  color: white;
}

/* Dropdown Menu */
.dropdown {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dropdown-toggle {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: #4caf50;
  border-bottom: 1px solid white;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #45a049;
}

.auth-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.login-button,
.register-button {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.google-signin-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#google-signin-button {
  width: 250px;
}

.instruction-content .turn-images {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.instruction-content .turn-images img {
  max-width: 48%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.instruction-content .disclaimer {
  color: rgba(0, 0, 0, 0.6); /* Fainter color */
  font-size: 14px; /* Slightly smaller font size */
  margin-top: 20px;
}
/* For both popup.css and Upload.css */
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 3px solid rgba(76, 175, 80, 0.3); /* #4caf50 with opacity */
  border-radius: 50%;
  border-top-color: #4caf50; /* Solid #4caf50 for the spinning part */
  animation: spin 1s ease-in-out infinite;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Update button styles to handle spinner alignment */
.analysis-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* When button is disabled, make the spinner slightly transparent */
button:disabled .loading-spinner {
  border-top-color: rgba(76, 175, 80, 0.7);
  border-color: rgba(76, 175, 80, 0.2);
}
.chess-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.chess-display {
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
}

.action-button {
  min-width: 150px;
  height: 40px;
  font-size: 14px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.action-button:active {
  background-color: #3e8e41;
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.action-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}
/* Add to your existing CSS */
.chess-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  width: 100%;
}

.board-comparison {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.board-section {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.board-section h3 {
  margin: 0;
  color: white;
}

/* Style for highlighting pieces */
.piece-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  transition: transform 0.2s;
}

.piece-container:hover {
  transform: scale(1.1);
}

/* Reset button styling */
.reset-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.reset-button:hover {
  background-color: #5a6268;
}
.move-simulation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 10px 0;
}

.move-input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.board-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

/* Add these styles */
.audio-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
}

.audio-checkbox {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0;
  accent-color: #4caf50;
}

.audio-label {
  color: white;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
