/* Checkout Page */
.checkout-page {
  min-height: 100vh;
  background: linear-gradient(to right, blue, purple);
  color: white;
  padding: 40px 20px;
}

.checkout-form {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
  color: white;
}

.checkout-form h1 {
  margin-bottom: 20px;
}

.checkout-form p {
  margin-bottom: 20px;
}

.checkout-form .StripeElement {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkout-form button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.checkout-form button:hover {
  background-color: #45a049;
}

.checkout-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkout-form button:disabled:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: none;
  box-shadow: none;
}

.checkout-form select:disabled,
.checkout-form .StripeElement--disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Optional: Add a loading spinner */
button[type="submit"]:disabled::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tier-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.tier-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.tier-card.selected {
  border-color: #64ffda;
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.tier-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.tier-header {
  font-size: 28px;
  font-weight: 700;
  color: #64ffda;
  margin-bottom: 20px;
}

.tier-price {
  font-size: 48px;
  font-weight: 700;
  color: white;
  margin: 20px 0;
}

.tier-features {
  margin: 30px 0;
}

.tier-features li {
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  gap: 12px;
}

.tier-features li::before {
  content: "✓";
  color: #64ffda;
  font-weight: bold;
  font-size: 18px;
}

.savings-tag {
  position: absolute;
  top: 20px;
  right: -85px;
  background: linear-gradient(135deg, #4c00b0, #4caf50);
  color: white;
  padding: 8px 80px;
  transform: rotate(45deg);
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 280px;
  text-align: right;
}

.per-month {
  font-size: 18px;
  opacity: 0.8;
}

.original-price {
  text-decoration: line-through;
  opacity: 0.7;
  font-size: 14px;
}

.savings-detail {
  margin-top: 12px;
  font-style: italic;
}

.payment-section {
  margin-top: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  text-align: center;
}

.payment-section h2 {
  margin-bottom: 15px;
  color: #FFD700;
}

button[type="submit"] {
  background: linear-gradient(135deg, #4c00b0, #4caf50);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background: linear-gradient(135deg, #3b0087, #45a049);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button[type="submit"]:disabled {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  transform: none;
}
