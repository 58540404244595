.message {
  margin-top: 10px;
  color: red; /* Set the color to red for visibility */
  font-weight: bold;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.form-container input {
  margin: 10px 0;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-container button {
  padding: 10px 20px;
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-weight: bold; /* Makes text bold */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for background color and transform */
}

.form-container button:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

.form-container button:active {
  background-color: #3e8e41; /* Even darker green when clicked */
  transform: translateY(1px); /* Pushed effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow */
}

.form-container p {
  color: white;
  margin-top: 10px;
}
