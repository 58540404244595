.terms-of-service {
  min-height: 100vh; /* Ensures at least full viewport height but allows expansion */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(to right, blue, purple);
  color: white;
  text-align: left;
  padding: 20px;
  overflow-y: auto; /* Ensures the page is scrollable when content overflows */
}

.terms-of-service h1 {
  text-align: center;
}

.terms-of-service p,
.terms-of-service ul {
  max-width: 800px;
  margin: 10px 0;
}

.terms-of-service ul {
  padding-left: 20px;
}

.terms-of-service ul li {
  list-style-type: disc;
}
