/* Purchase Credits Page */
.purchase-credits-page {
  min-height: 100vh;
  background: linear-gradient(to right, blue, purple);
  color: white;
  padding: 40px 20px;
}

.purchase-credits-form {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  max-width: 600px;
  margin: 0 auto;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.purchase-credits-form h1 {
  margin-bottom: 20px;
}

.purchase-credits-form p {
  margin-bottom: 20px;
}

.purchase-credits-form .StripeElement {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.credit-calculator {
  display: grid;
  gap: 25px;
  margin: 30px 0;
}

.credit-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.credit-item:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.credit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.credit-label {
  font-size: 20px;
  font-weight: 600;
  color: #4caf50;
}

.credit-price {
  font-size: 16px;
  opacity: 0.8;
}

.credit-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.credit-input {
  display: flex;
  align-items: center;
  gap: 15px;
}

.credit-input input {
  width: 100px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 18px;
  padding: 0 15px;
  text-align: center;
}

.credit-slider {
  width: 100%;
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  outline: none;
}

.credit-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
  transition: all 0.2s ease;
}

.credit-slider::-webkit-slider-thumb:hover {
  transform: scale(1.1);
}

.total-section {
  background: rgba(76, 175, 80, 0.1);
  border-radius: 15px;
  padding: 25px;
  margin: 30px 0;
  text-align: center;
}

.total-price {
  font-size: 36px;
  font-weight: 700;
  color: #4caf50;
}

.card-element-container {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  margin: 30px 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

button[type="submit"] {
  background: linear-gradient(135deg, #4c00b0, #4caf50);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background: linear-gradient(135deg, #3b0087, #45a049);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button[type="submit"]:disabled {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  transform: none;
}

/* Style for the Stripe Element iframe */
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Most styles can be inherited from Checkout.css */
/* Just add these specific styles for the calculator */

.calculator-card {
  max-width: 600px;
  margin: 30px auto;
}

.credit-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 25px 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group label {
  color: #FFD700;
  font-size: 18px;
  font-weight: 500;
}

.input-with-price {
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.input-with-price input {
  flex: 1;
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  padding: 8px;
}

.input-with-price input:focus {
  outline: none;
}

.price-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.credit-input input:disabled,
.credit-slider:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.stripe-form button:disabled {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  transform: none;
}

.stripe-form button:disabled:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: none;
  box-shadow: none;
}

/* Loading spinner for processing state */
.stripe-form button:disabled::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

button[type="button"] {
  background: linear-gradient(135deg, #4c00b0, #4caf50);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
}

button[type="button"]:hover {
  background: linear-gradient(135deg, #3b0087, #45a049);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button[type="button"]:disabled {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  transform: none;
}

button[type="button"]:disabled:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: none;
  box-shadow: none;
}

button[type="button"]:disabled::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
