.main-page {
  /* Removed 'height: 100vh;' to avoid fixed height constraints on mobile */
  min-height: 100vh; /* Ensures at least full viewport height but allows expansion */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Changed to 'flex-start' to accommodate longer content and scrolling */
  background: linear-gradient(to right, blue, purple);
  color: white;
  text-align: center;
  padding-bottom: 20px; /* Adds spacing at the bottom */
  overflow-y: auto; /* Ensures the page is scrollable when content overflows */
}

.button-container {
  margin-top: 20px; /* Adjust spacing between buttons and other elements */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.chrome-extension-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  transition: transform 0.2s ease;
}

.chrome-extension-link:hover {
  transform: scale(1.02);
}

.feature-card {
  background: rgba(255, 255, 255, 0.1); /* Translucent background */
  border-radius: 15px;
  padding: 30px;
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card .chrome-extension-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  transition: transform 0.2s ease;
}

.feature-card .chrome-extension-link:hover {
  transform: scale(1.02);
}

.register-button,
.login-button,
.contract-button {
  margin: 0 10px; /* Add horizontal space between the buttons */
  padding: 10px 20px;
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-weight: bold; /* Makes text bold */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for background color and transform */
  font-size: 16px; /* Consistent font size for all buttons */
}

.register-button:hover,
.login-button:hover,
.contract-button:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

.register-button:active,
.login-button:active,
.contract-button:active {
  background-color: #3e8e41; /* Even darker green when clicked */
  transform: translateY(1px); /* Pushed effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow */
}

@media (max-width: 768px) {
  .main-page h1 {
    font-size: 22px; /* Smaller font size for better readability */
    margin: 20px 0; /* Adds some spacing */
  }

  .main-page .chess-image img {
    max-width: 90%; /* More responsive image sizing */
  }

  .register-button,
  .login-button,
  .contract-button {
    margin: 0 5px; /* Reduced horizontal space between buttons on smaller screens */
  }

  .feature-card {
    width: 100%;
    max-width: 300px;
  }

  .future-features {
    gap: 20px;
  }

  .guide-steps {
    padding-left: 20px;
  }
}

.chess-image {
  margin-top: 20px;
  max-width: 300px;
  height: auto;
  display: flex;
  justify-content: center; /* Ensure image is centered */
}

.contact-container {
  margin-top: 20px;
  font-size: 16px;
}

.contact-container a {
  color: white;
  text-decoration: none;
}

.contact-container a:hover {
  text-decoration: underline;
}

.links-container {
  margin-top: 20px;
  font-size: 16px; /* Consistent font size for links */
}

.links-container a,
.links-container span {
  color: white; /* White text for links and separator */
  margin: 0 10px; /* Space between links */
  text-decoration: none; /* Remove underline */
}

.links-container a:hover {
  text-decoration: underline; /* Underline on hover */
}

.disclaimer {
  text-align: center;
  margin-top: auto; /* Helps push to the bottom, but ensure content above doesn't push it off screen */
  padding: 20px;
}

body {
  margin: 0;
  padding-bottom: env(safe-area-inset-bottom); /* Use environment variables for safe area on iOS */
}

.register-button,
.login-button {
  width: 250px; /* Adjust this value to match your desired button width */
}

.google-signin-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#google-signin-button {
  width: 250px; /* This should match the width set in the renderButton options */
}

.tier-container {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
}

.tier-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.tier-button {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 20px;
  width: 250px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: white;
  text-align: left;
}

.tier-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.tier-button h3 {
  font-size: 24px;
  margin: 0 0 10px 0;
}

.tier-button .price {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 15px 0;
}

.tier-button ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tier-button li {
  margin-bottom: 8px;
  font-size: 16px;
}

.features-container {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.features-grid {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.feature-icon {
  color: white;
  margin-bottom: 15px;
}

.feature-card h3 {
  margin: 0 0 15px 0;
  font-size: 20px;
}

.feature-card p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}

.quick-guide {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
}

.guide-steps {
  text-align: left;
  max-width: 600px;
  margin: 20px auto;
  padding-left: 40px;
}

.guide-steps li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
}

.coming-soon {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
}

.future-features {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.future-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.future-feature p {
  margin: 0;
  font-size: 16px;
}
